@font-face {
  font-family: Archivo;
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: url("archivo-vietnamese-200-italic.fbfd795f.woff2") format("woff2"), url("archivo-all-200-italic.0d907747.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Archivo;
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: url("archivo-latin-ext-200-italic.b1e2f1e7.woff2") format("woff2"), url("archivo-all-200-italic.0d907747.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Archivo;
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: url("archivo-latin-200-italic.f70615d5.woff2") format("woff2"), url("archivo-all-200-italic.0d907747.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Archivo;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("archivo-vietnamese-300-normal.b8a44ab4.woff2") format("woff2"), url("archivo-all-300-normal.061cffc4.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Archivo;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("archivo-latin-ext-300-normal.075e0c77.woff2") format("woff2"), url("archivo-all-300-normal.061cffc4.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Archivo;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("archivo-latin-300-normal.ec79d0d3.woff2") format("woff2"), url("archivo-all-300-normal.061cffc4.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Archivo;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("archivo-vietnamese-500-normal.2629369c.woff2") format("woff2"), url("archivo-all-500-normal.dea6ca5d.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Archivo;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("archivo-latin-ext-500-normal.ea63bdc8.woff2") format("woff2"), url("archivo-all-500-normal.dea6ca5d.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Archivo;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("archivo-latin-500-normal.bd94235b.woff2") format("woff2"), url("archivo-all-500-normal.dea6ca5d.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Archivo;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("archivo-vietnamese-500-italic.7743e53f.woff2") format("woff2"), url("archivo-all-500-italic.f68a2607.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Archivo;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("archivo-latin-ext-500-italic.0a7a2035.woff2") format("woff2"), url("archivo-all-500-italic.f68a2607.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Archivo;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("archivo-latin-500-italic.0148f7d2.woff2") format("woff2"), url("archivo-all-500-italic.f68a2607.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Bebas Neue;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("bebas-neue-latin-ext-400-normal.0073f4a8.woff2") format("woff2"), url("bebas-neue-all-400-normal.cc47eebd.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Bebas Neue;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("bebas-neue-latin-400-normal.9d76def0.woff2") format("woff2"), url("bebas-neue-all-400-normal.cc47eebd.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Bowlby One SC;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("bowlby-one-sc-latin-ext-400-normal.f79e027a.woff2") format("woff2"), url("bowlby-one-sc-all-400-normal.03ed7953.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Bowlby One SC;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("bowlby-one-sc-latin-400-normal.94e5d312.woff2") format("woff2"), url("bowlby-one-sc-all-400-normal.03ed7953.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  touch-action: none;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Archivo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  overflow: hidden;
}

body {
  cursor: grab;
}

body, #root {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

.center {
  transform: translateX(50vw);
}

.element {
  box-sizing: border-box;
  border-radius: .4rem;
  padding: 1.5rem;
  line-height: 1.5;
  position: absolute;
  overflow: hidden;
}

.element:not(.image) {
  background: #0000001a;
}

.element.audio {
  border-radius: 50%;
  display: flex;
}

.element.audio > * {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-grow: 1;
  place-content: center;
  align-items: center;
  display: flex;
}

.element.audio > .forward {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M4.05%2016.975q-.5.35-1.025.05-.525-.3-.525-.9v-8.25q0-.6.525-.9.525-.3%201.025.05l6.2%204.15q.45.3.45.825%200%20.525-.45.825Zm10%200q-.5.35-1.025.05-.525-.3-.525-.9v-8.25q0-.6.525-.9.525-.3%201.025.05l6.2%204.15q.45.3.45.825%200%20.525-.45.825ZM4.5%2012Zm10%200Zm-10%202.25L7.9%2012%204.5%209.75Zm10%200L17.9%2012l-3.4-2.25Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .forward:hover {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M4.05%2016.975q-.5.35-1.025.05-.525-.3-.525-.9v-8.25q0-.6.525-.888.525-.287%201.025.038l6.2%204.15q.45.3.45.825%200%20.525-.45.825Zm10%200q-.5.35-1.025.05-.525-.3-.525-.9v-8.25q0-.6.525-.888.525-.287%201.025.038l6.2%204.15q.45.3.45.825%200%20.525-.45.825Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .pause {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M15%2019q-.825%200-1.412-.587Q13%2017.825%2013%2017V7q0-.825.588-1.412Q14.175%205%2015%205h2q.825%200%201.413.588Q19%206.175%2019%207v10q0%20.825-.587%201.413Q17.825%2019%2017%2019Zm-8%200q-.825%200-1.412-.587Q5%2017.825%205%2017V7q0-.825.588-1.412Q6.175%205%207%205h2q.825%200%201.413.588Q11%206.175%2011%207v10q0%20.825-.587%201.413Q9.825%2019%209%2019Zm8-2h2V7h-2Zm-8%200h2V7H7ZM7%207v10Zm8%200v10Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .pause:hover {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M15%2019q-.825%200-1.412-.587Q13%2017.825%2013%2017V7q0-.825.588-1.412Q14.175%205%2015%205h2q.825%200%201.413.588Q19%206.175%2019%207v10q0%20.825-.587%201.413Q17.825%2019%2017%2019Zm-8%200q-.825%200-1.412-.587Q5%2017.825%205%2017V7q0-.825.588-1.412Q6.175%205%207%205h2q.825%200%201.413.588Q11%206.175%2011%207v10q0%20.825-.587%201.413Q9.825%2019%209%2019Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .play {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M9.525%2018.025q-.5.325-1.013.037Q8%2017.775%208%2017.175V6.825q0-.6.512-.888.513-.287%201.013.038l8.15%205.175q.45.3.45.85t-.45.85ZM10%2012Zm0%203.35L15.25%2012%2010%208.65Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .play:hover {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M9.525%2018.025q-.5.325-1.013.037Q8%2017.775%208%2017.175V6.825q0-.6.512-.888.513-.287%201.013.038l8.15%205.175q.45.3.45.85t-.45.85Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .rewind {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22m19.95%2016.975-6.2-4.15q-.45-.3-.45-.825%200-.525.45-.825l6.2-4.15q.5-.35%201.025-.05.525.3.525.9v8.25q0%20.6-.525.9-.525.3-1.025-.05Zm-10%200-6.2-4.15q-.45-.3-.45-.825%200-.525.45-.825l6.2-4.15q.5-.35%201.025-.05.525.3.525.9v8.25q0%20.6-.525.9-.525.3-1.025-.05ZM9.5%2012Zm10%200Zm-10%202.25v-4.5L6.1%2012Zm10%200v-4.5L16.1%2012Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.audio > .rewind:hover {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22transform%3Arotate%28360deg%29%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22m19.975%2016.975-6.2-4.15q-.45-.3-.45-.825%200-.525.45-.825l6.2-4.15q.5-.325%201.025-.038.525.288.525.888v8.25q0%20.6-.525.9-.525.3-1.025-.05Zm-10%200-6.2-4.15q-.45-.3-.45-.825%200-.525.45-.825l6.2-4.15q.5-.325%201.025-.038.525.288.525.888v8.25q0%20.6-.525.9-.525.3-1.025-.05Z%22%2F%3E%3C%2Fsvg%3E");
}

.element.image {
  background-size: cover;
}

.element.text {
  height: fit-content !important;
}

.element.text > :first-child, .element.text > .ProseMirror > :first-child {
  margin-top: 0;
}

.element.text > :last-child, .element.text > .ProseMirror > :last-child {
  margin-bottom: 0;
}

.element.text strong {
  font-weight: 500;
}

.element.text ul, .element.text ol {
  padding-left: 1rem;
}

.element.text ul {
  list-style: circle;
}

.element.text li p {
  margin: 0;
}

.element.text a {
  color: inherit;
}

.element.text a:hover {
  text-decoration: none;
}

.element.text blockquote {
  border-left: solid #999;
  margin: 0;
  padding-left: 1rem;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 200;
}

.element.text h1, .element.text h2, .element.text h3, .element.text h4, .element.text h5, .element.text h6 {
  margin: 0;
  line-height: 1;
}

.element.text h1 {
  font-size: 4.2rem;
}

.element.text h2 {
  font-size: 2.6rem;
}

.element.text h3 {
  font-size: 1.8rem;
}

.element.text h4 {
  font-size: 1.4rem;
}

.element.text h5 {
  font-size: 1.2rem;
}

.element.text h6 {
  font-size: 1.1rem;
}



/*# sourceMappingURL=index.css.map */
