@import "npm:@fontsource/archivo/200-italic.css";
@import "npm:@fontsource/archivo/300.css";
@import "npm:@fontsource/archivo/500.css";
@import "npm:@fontsource/archivo/500-italic.css";
@import "npm:@fontsource/bebas-neue";
@import "npm:@fontsource/bowlby-one-sc";

* {
  touch-action: none;
}

html,
body {
  margin: 0rem;
  padding: 0rem;
  overflow: hidden;
  font-family: "Archivo", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

body {
  /* keep cursor style in single place so it's easy to change dynamically */
  cursor: grab;
}

body,
#root {
  height: 100vh;
  max-height: 100vh;

  width: 100vw;
  max-width: 100vw;
}

.center {
  transform: translateX(50vw);
}

.element {
  box-sizing: border-box;
  line-height: 1.5;
  overflow: hidden;
  padding: 1.5rem;
  position: absolute;
  border-radius: 0.4rem;
}

.element:not(.image) {
  background: rgba(0, 0, 0, 10%);
}

.element.audio {
  display: flex;
  border-radius: 50%;
}

.element.audio > * {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.element.audio > * {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.element.audio > .forward {
  background-image: url("data-url:./icon/forward-outline.svg");
}

.element.audio > .forward:hover {
  background-image: url("data-url:./icon/forward-filled.svg");
}

.element.audio > .pause {
  background-image: url("data-url:./icon/pause-outline.svg");
}

.element.audio > .pause:hover {
  background-image: url("data-url:./icon/pause-filled.svg");
}

.element.audio > .play {
  background-image: url("data-url:./icon/play-outline.svg");
}

.element.audio > .play:hover {
  background-image: url("data-url:./icon/play-filled.svg");
}

.element.audio > .rewind {
  background-image: url("data-url:./icon/rewind-outline.svg");
}

.element.audio > .rewind:hover {
  background-image: url("data-url:./icon/rewind-filled.svg");
}

.element.image {
  background-size: cover;
}

.element.text {
  /* todo fixme experiment: always fit height of text-elements */
  height: fit-content !important;
}

.element.text > *:first-child,
.element.text > .ProseMirror > *:first-child {
  margin-top: 0rem;
}

.element.text > *:last-child,
.element.text > .ProseMirror > *:last-child {
  margin-bottom: 0rem;
}

.element.text strong {
  font-weight: 500;
}

.element.text ul,
.element.text ol {
  padding-left: 1rem;
}

.element.text ul {
  list-style: circle;
}

.element.text li p {
  margin: 0rem;
}

.element.text a {
  color: inherit;
}

.element.text a:hover {
  text-decoration: none;
}

.element.text blockquote {
  border-left: medium solid #999;
  padding-left: 1rem;
  font-style: italic;
  font-weight: 200;
  font-size: 1.1rem;
  margin: 0rem;
}

.element.text h1,
.element.text h2,
.element.text h3,
.element.text h4,
.element.text h5,
.element.text h6 {
  line-height: 1;
  margin: 0rem;
}

.element.text h1 {
  font-size: 4.2rem;
}

.element.text h2 {
  font-size: 2.6rem;
}

.element.text h3 {
  font-size: 1.8rem;
}

.element.text h4 {
  font-size: 1.4rem;
}

.element.text h5 {
  font-size: 1.2rem;
}

.element.text h6 {
  font-size: 1.1rem;
}
